<template>
    <div class="center">
        <HeroCarousel :items="heroCarouselItems" :hasHeaderOffset="true" />
        <section class="intro">
            <div class="intro-inner">
                <TagFeedBlock
                    v-for="feed in topicRegionContentFeed"
                    v-bind="feed"
                    :key="feed.id"
                    :pageLanguage="pageLanguage"
                    class="intro-column"
                />

                <div class="intro-column about-section">
                    <GenericCard v-if="about" :heading="aboutHeading" class="about-card">
                        <template #middle>
                            <div class="about">
                                <RichTextElements :elements="about" bodyStyle="serif-small" />
                            </div>
                        </template>

                        <template #bottom>
                            <Button :url="currentCenter.about + '?lang=' + pageLanguage" variant="secondary" size="large" icon="arrow-forward">{{
                                aboutButtonText
                            }}</Button>
                        </template>
                    </GenericCard>

                    <div class="donate-button" v-if="currentCenter.id === 'global'">
                        <Button :url="'https://donate.carnegieendowment.org/support'" variant="primary" size="large"
                            >Donate to Support Carnegie</Button
                        >
                    </div>

                    <GenericCard
                        v-if="trendingTags?.length"
                        :heading="$t('trending topics and regions')"
                        class="about-card"
                    >
                        <template #middle>
                            <div class="tags">
                                <TagCluster :items="trendingTags" :pageLanguage="pageLanguage" />
                            </div>
                        </template>

                        <template #bottom>
                            <div class="topics-regions-links">
                                <Button variant="tertiary" @click="openRegionModal = true">{{
                                    $t('see all regions')
                                }}</Button>
                                <Button variant="tertiary" @click="openTopicModal = true">{{
                                    $t('see all topics')
                                }}</Button>
                            </div>
                        </template>
                    </GenericCard>
                </div>
            </div>
        </section>

        <StreamTierFeed :streamTiered="streamTiered" />
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
const { pageLanguage } = usePageDataStore();

const { t } = useI18n();
const { currentCenter } = useCenters();

const headerAttributesStore = useHeaderAttributesStore();
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent', text: 'light' });
});

const props = defineProps({
    heroCarousel: {
        type: Object,
    },
    topicRegionContentFeed: {
        type: Array,
    },
    about: {
        type: Object,
    },
    trendingTopicRegionTag: {
        type: Object,
    },
    title: {
        type: String,
    },
    streamTiered: {
        type: Array,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const heroCarouselItems = computed(() => getContentFeedItems(props.heroCarousel?.feed, props.heroCarousel?.dynamic));

const aboutHeading = computed(() => {
    if (currentCenter.value.slug === 'global') {
        return t('about the carnegie endowment');
    } else {
        return t('about the center');
    }
});

const aboutButtonText = computed(() => {
    if (currentCenter.value.slug === 'global') {
        return t('who we are');
    } else {
        return t('more on the center');
    }
});

const trendingTags = computed(() => {
    return props.trendingTopicRegionTag?.map((tag) => tag.value);
});

const seoTitle = computed(() => {
    if (props.locale !== 'en') {
        return t(currentCenter.value.title);
    } else {
        return currentCenter.value.title;
    }
});

useSeoMeta({
    title: `${seoTitle.value}`,
});

const { openTopicModal, openRegionModal } = storeToRefs(headerAttributesStore);
const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Center Homepage',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
});
</script>

<style lang="scss" scoped>
.intro {
    background-color: color(offblanc);
}

.intro-inner {
    padding-top: 3.5rem;
    padding-bottom: 5.6rem;
    align-items: flex-start;
    @include content-section;
    @include grid;
}

.intro-column {
    grid-column: span 4;

    @include media-query(tablet) {
        grid-column: 1 / -1;
    }
}

.about-section {
    display: flex;
    flex-direction: column;
    gap: var(--col-gutter);

    @include media-query(tablet) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.about-card {
    flex: 1;
}

.about {
    margin-bottom: 2.4rem;
}

.tags {
    margin-bottom: 3.6rem;
}

.topics-regions-links {
    display: flex;
    gap: var(--col-gutter);
    flex-wrap: wrap;
}

:deep(.donate-button) {
    margin: 0 auto;
    .button-primary {
        width: fit-content;
    }
    @include media-query(mobile) {
        display: inline-flex;
        flex: 1 10%;
        flex-flow: column;
        order: 3;
    }
}
:deep(.generic-card) {
    @include media-query(mobile) {
        flex: 1 40%;
    }
}
:deep(.generic-card .button .label) {
    @include media-query(tablet) {
        width: auto;
    }
}
</style>
