<template>
    <section class="hero-carousel" :class="CSSClasses" :dir="direction">
        <Carousel :items-to-show="1" wrapAround ref="HeroCarousel" :dir="direction">
            <Slide v-for="(item, index) in items" :key="index">
                <div v-if="item" class="carousel-slide">
                    <template v-if="item.overrideImage">
                        <Image v-bind="item.overrideImage" class="image" />
                    </template>

                    <template v-else>
                        <Image v-if="item.summary?.hero" v-bind="item.summary?.hero.image" class="image" />
                    </template>

                    <Image v-if="item.image" v-bind="item.image" class="image" />

                    <div class="content-wrapper">
                        <div class="content">
                            <Anchor v-if="item.title" :url="item.path + '?lang=' + pageLanguage">
                                <Typography class="title" as="p" variant="h1">
                                    {{ item.title }}
                                </Typography>
                            </Anchor>

                            <AvatarSet
                                v-if="item.summary?.contributors?.length"
                                :people="item.summary.contributors"
                                class="credits"
                                theme="dark"
                                :pageLanguage="pageLanguage"
                            />

                            <Typography v-if="item.header" class="title" as="p" variant="h1">
                                {{ item.header.headerTitle }}
                            </Typography>

                            <RichTextElements
                                class="subheader"
                                v-if="item.subheader && isDekNotEmpty(item.subheader)"
                                :elements="item.subheader"
                            />

                            <div v-if="item.buttons" class="buttons">
                                <CarnegieButton
                                    v-for="button in item.buttons"
                                    :key="button.button.label"
                                    v-bind="{ ...button.button }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </Carousel>
        <div class="carousel-controls-wrapper">
            <div class="carousel-navigation">
                <button
                    class="gallery-nav-button gallery-nav-button-prev"
                    aria-label="Navigate to previous slide"
                    @click="handlePrevClicked"
                >
                    <Icon class="arrow-icon" name="arrow-left" />
                </button>
                <button
                    class="gallery-nav-button gallery-nav-button-next"
                    aria-label="Navigate to next slide"
                    @click="handleNextClicked"
                >
                    <Icon class="arrow-icon" name="arrow-right" />
                </button>
            </div>
        </div>
    </section>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
const direction = inject('direction');
const { pageLanguage } = usePageDataStore();

const HeroCarousel = ref(null);

const handleNextClicked = () => {
    HeroCarousel.value.next();
};
const handlePrevClicked = () => {
    HeroCarousel.value.prev();
};

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    hasHeaderOffset: {
        type: Boolean,
        default: false,
    },
});

const CSSClasses = computed(() => {
    return [`direction-${direction.value}`, props.hasHeaderOffset && 'header-offset'];
});
</script>

<style lang="scss" scoped>
.hero-carousel {
    position: relative;
}
.carousel-slide {
    position: relative;
    height: 70vh;
    width: 100%;
    background: color(blue, dark);

    .header-offset & {
        height: calc(70vh + var(--header-height));
    }

    @include media-query(tablet) {
        height: 50vw;
        .header-offset & {
            height: calc(50vw + var(--header-height));
        }
    }

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(var(--header-height) * 2);
        background: linear-gradient(to bottom, color(noir, default, 0.5), transparent);
        @include z-index(arbitrary);
    }
}

.image {
    @include z-index(below-arbitrary);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.content-wrapper {
    @include z-index(arbitrary);
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    background: linear-gradient(to top, color(noir, default, 0.8), transparent);

    :deep(.avatars) {
        float: left;
        margin-right: 1rem;
        width: fit-content;
    }
    :deep(.names) {
        float: left;
        width: fit-content;
    }
}

.content {
    @include content-section;
    padding-bottom: vertical-space(2);

    .direction-ltr & {
        text-align: left;
        @include media-query(full) {
            padding-right: calc(var(--page-margin) * 6);
        }
    }

    .direction-rtl & {
        text-align: right;
        @include media-query(full) {
            padding-left: calc(var(--page-margin) * 6);
        }
    }
}

.title {
    color: color(blanc);
    margin-bottom: 1.5rem;
}

.subheader {
    color: color(blanc, default, 0.9);
}
.buttons {
    display: inline-flex;
    gap: 0.8rem;
    margin-top: vertical-space(2);
}

.carousel-controls-wrapper {
    position: absolute;
    width: 100%;
    bottom: 50%;

    @include media-query(tablet-mw) {
        width: auto;
        bottom: vertical-space(2);
        right: 0;
    }
    .direction-rtl & {
        @include media-query(full) {
            right: auto;
            left: 0;
        }
    }
}
.carousel-navigation {
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    @include padding-lr(var(--page-margin));
}

.gallery-nav-button {
    border: 1px solid color(blanc, default, 0.4);
    padding: 1.5rem;
    border-radius: 100rem;
    width: 5.6rem;
    height: 5.6rem;

    .arrow-icon {
        color: color(blanc);
    }
}
</style>

<style lang="scss">
// We have to put styles that override the default vue3-carousel styles in an unscoped tag
.gallery-block {
    .carousel {
        position: relative;
    }
}
</style>
